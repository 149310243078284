import React from "react"
import Lessons from "../components/Lessons/Lessons";
import LessonsNav from '../components/Lessons/Nav'

const LessonsPage = (props) => {
  if (typeof window === "undefined") return null

  return <>
    <LessonsNav {...props} />
    <Lessons />
  </>
}

export default LessonsPage
