import { Link } from 'gatsby'
import { Star } from 'grommet-icons'
import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import { colors } from '../../../theme'
import Avatar from '../../UI/Avatar'
import Button from '../../UI/Button'
import LessonCard from '../../UI/LessonCard'
import LessonsState from './index.state'
import InfiniteScroll from 'react-infinite-scroller'
import { CheckBox, TextInput } from 'grommet'

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  > :last-child {
    margin-left: auto;
  }
`

const SearchWrapper = styled.div`
  display: flex;
  max-width: 220px;
`

const ToggleFilters = styled.div`
  margin-left: 40px;
  > div {
    display: flex;
    align-items: center;
    > :first-child { margin-right: 8px; }
  }
`

const LessonsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
`


const Lessons = (props) => {
  const { lessons, displayedLessons, updateLessonAtIndex, fetchMore, hasMore, onSearchChange, searchString, updateFilters, filters } = LessonsState.useContainer()

  const feature = (lesson, index) => {
    updateLessonAtIndex(index, { featured: !lesson.featured })
  }

  const onPublish = (lesson) => {
    if (lesson.index >= 0) {
      updateLessonAtIndex(lesson.index, { published: true })
    }
  }

  const onPrivate = (lesson) => {
    if (lesson.index >= 0) {
      updateLessonAtIndex(lesson.index, { private: !lesson.private })
    }
  }

  return <div>
    <Header>
      <SearchWrapper>
        <TextInput placeholder='Search for a lesson title' value={searchString} onChange={e => onSearchChange(e.target.value)} />
      </SearchWrapper>
      <ToggleFilters>
      <div>
        <CheckBox checked={filters.showPrivate} onChange={(e) => updateFilters({ showPrivate: e.target.checked })} />
        <div>Show private</div>
      </div>
      <div>
        <CheckBox checked={filters.showPublic} onChange={(e) => updateFilters({ showPublic: e.target.checked })} />
        <div>Show public</div>
      </div>
      <div>
        <CheckBox checked={filters.showPublished} onChange={(e) => updateFilters({ showPublished: e.target.checked })} />
        <div>Show published</div>
      </div>
      <div>
        <CheckBox checked={filters.showUnpublished} onChange={(e) => updateFilters({ showUnpublished: e.target.checked })} />
        <div>Show not published</div>
      </div>
      </ToggleFilters>
      <Link to='/create-lesson'><Button>Create</Button></Link>
    </Header>
    <InfiniteScroll
      pageStart={0}
      loadMore={fetchMore}
      hasMore={hasMore}
      loader={<div className="loader" key={0}>Loading ...</div>}
      useWindow={true}
    >
      <LessonsGrid>
        { (displayedLessons || []).map((l, i) => <LessonCard lesson={l} linkTo={`/lesson/${l._id}`} publish={() => onPublish(l)} togglePrivate={() => onPrivate(l)} />) }
      </LessonsGrid>
    </InfiniteScroll>
  </div>
}

Lessons.propTypes = {

}

Lessons.defaultProps = {

}

export default props => <LessonsState.Provider>
  <Lessons {...props} />
</LessonsState.Provider>
