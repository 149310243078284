import { createContainer } from "unstated-next"
import { useState, useEffect, useMemo } from "react"
import { useApolloClient } from "@apollo/client"
import GET_LESSONS from './getLessons.graphql'
import UPDATE_LESSON from '../CreateLesson/updateLesson.graphql'
import debounce from 'lodash.debounce'

const PAGE_SIZE = 10

const useLessonsState = () => {
  const [lessons, setLessons] = useState([])
  const [displayedLessons, setDisplayedLessons] = useState([])
  const [searchString, setSearchString] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const client = useApolloClient()
  const [filters, setFilters] = useState({ showPrivate: true, showPublic: true, showPublished: true, showUnpublished: true })

  const fetchMore = async (page) => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_LESSONS,
      fetchPolicy: 'network-only',
      variables: {
        skip: page * PAGE_SIZE,
        limit: PAGE_SIZE
      }
    })
    if (data.lessonsV2.length < PAGE_SIZE) {
      setHasMore(false)
    }
    setLessons(lessons => [...lessons, ...data.lessonsV2].map((l, i) => ({ ...l, index: i })))
    setLoading(false)
  }

  const updateLessonAtIndex = async (index, payload) => {
    await updateLesson(lessons[index]._id, payload)
    setLessons((lessons) => {
      let newLessons = [...lessons]
      newLessons[index] = { ...newLessons[index], ...payload }
      return newLessons
    })
  }

  const updateLesson = async (_id, payload) => {
    await client.mutate({
      mutation: UPDATE_LESSON,
      variables: {
        _id: _id,
        input: payload
      }
    })
  }

  const onSearchChange = (value) => {
    setSearchString(value)
  }


  useEffect(() => {
    const filter = debounce(() => {
      setDisplayedLessons(lessons.filter(l => {
        if (!filters.showPrivate && l.private) return false
        if (!filters.showPublic && !l.private) return false
        if (!filters.showPublished && l.published) return false
        if (!filters.showUnpublished && !l.published) return false
        return new RegExp(searchString).test(l.title)
      }))
    }, 300)
    if (!searchString || searchString.length < 3) {
      setDisplayedLessons(lessons.filter(l => {
        if (!filters.showPrivate && l.private) return false
        if (!filters.showPublic && !l.private) return false
        if (!filters.showPublished && l.published) return false
        if (!filters.showUnpublished && !l.published) return false
        return true
      }))
    } else {
      filter()
    }

  }, [lessons, searchString, filters])


  const updateFilters = (payload) => {
    setFilters(filters => {
      return { ...filters, ...payload }
    })
  }

  return { lessons, displayedLessons, loading, updateLessonAtIndex, fetchMore, hasMore, onSearchChange, searchString, filters, updateFilters }
}

export default createContainer(useLessonsState)
