import { Link } from 'gatsby'
import { Star } from 'grommet-icons'
import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import { colors } from '../../../theme'
import Avatar from '../Avatar'
import Button from '../Button'

interface Props {
  linkTo?: string,
  lesson: {
    _id: string,
    title: string,
    createdBy?: {
      name: string
    },
    backgroundUrl: string,
    createdAt?: string,
    featured?: boolean,
    private?: boolean
  },
  togglePrivate?: () => void,
  publish?: () => void,
  feature?: () => void
}

const Wrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  background-color: ${colors.indigo};
  color: #fff;
`

const Thumbnail = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-image: url("${(props: { src: string }) => props.src}");
  }
`

const Title = styled.div`
  padding: 8px 13px;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
`

const Actions = styled.div`
  display: flex;
  padding: 8px 8px 8px 8px;
  justify-content: flex-end;
`

const Information = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 8px;
`

const Author = styled.div`
  display: flex;
  align-items: center;
  > :first-child { margin-right: 10px; }
`

const Timestamp = styled.div`
  margin-left: auto;
`

export default (props: Props) => {

  const onFeatureAction = (e) => {
    e.stopPropagation()
    e.preventDefault()
    props.feature()
  }

  const onPublishAction = (e) => {
    e.stopPropagation()
    e.preventDefault()
    props.publish()
  }

  const onTogglePrivateAction = (e) => {
    e.stopPropagation()
    e.preventDefault()
    props.togglePrivate()
  }

  const Component = <Wrapper>
    <Thumbnail src={props.lesson.backgroundUrl}>
      <div />
    </Thumbnail>
    <Title>{props.lesson.title}</Title>
    <Information>
      { props.lesson.createdBy && <Author>
        <Avatar user={props.lesson.createdBy} />
        <div>{props.lesson.createdBy.name}</div>
      </Author> }
      <Timestamp>
        <FormattedDate value={props.lesson.createdAt} />
      </Timestamp>
    </Information>
    <Actions>
      { /* @ts-ignore */ }
      { props.feature && <Button bgColor={props.lesson.featured ? '#cc0' : colors.lighterGray} color='#000' onClick={onFeatureAction}>
        <Star color={props.lesson.featured ? '#ff0' : colors.lightGray} />
        { props.lesson.featured ? 'Unfeature' : 'Feature' }
      </Button> }
      { /* @ts-ignore */ }
      { props.togglePrivate && !props.lesson.private && <Button bgColor='#fff' color='#000' onClick={onTogglePrivateAction}>
        Make private
      </Button> }
      { /* @ts-ignore */ }
      { props.togglePrivate && props.lesson.private && <Button bgColor='#222' color='#fff' onClick={onTogglePrivateAction}>
        Make public
      </Button> }
      { /* @ts-ignore */ }
      { props.publish && !props.lesson.published && <Button bgColor='#fff' color='#000' onClick={onPublishAction}>
        Publish
      </Button> }
    </Actions>
  </Wrapper>

/**
 *     <div />
    <div>
      <div>{l.title}</div>
      <Author>
        <Avatar user={l.createdBy} size='50px' />
        <div>{l.createdBy.name}</div>
      </Author>
      <Timestamp></Timestamp>
    </div>
    { !l.published && <PublishButton bgColor={colors.green} onClick={(e) => onPublish(e, l, i)}>
      Publish
    </PublishButton> }
    <Feature onClick={(e) => onFeatureClick(e, l, i)}>
      <Star size='50px' color={l.featured ? '#ff0' : '#ebebeb'}/>
    </Feature>
 */

  if (props.linkTo) {
    return <Link to={props.linkTo}>
      { Component }
    </Link>
  }
}
